.container-solicitacao {
    margin: 3.5rem;
    padding: .5rem;
    width: 80%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: rgba( 225, 225, 225, 0.3 );
    box-shadow: 0 .5rem 1rem 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

}

.container-solicitacao h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #003775;
    margin-bottom: 3rem;
    top: 0;

}

.container-solicitacao p {
    font-size: 1.5rem;
    color: #6c757d;
    text-align: center;

}

.container-solicitacao .buttons{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 1rem;
}

.container-solicitacao .whatsapp-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #25D366;
    color: #fff;
    margin: 0;
    transition: .5s;

}

.container-solicitacao .whatsapp-btn:hover{
    cursor: pointer;
    background: transparent;
    color: #25D366;
}

.container-solicitacao .link-whatsapp{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: .5rem;
}

.container-solicitacao .link-whatsapp p{
    font-size: 1.5rem;
    color: #6c757d;
}

.container-solicitacao .btn-back{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    background: #003775;    
    color: #fff;
    margin: 0;
    transition: .5s;
    border: 1px solid #003775;
    border-radius: 10px;

}

.container-solicitacao .btn-back:hover{
    cursor: pointer;
    background: transparent;
    color: #003775;
    border: 1px solid #003775;
}