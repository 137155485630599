.logo {
    width: 8rem;
}

.title {
    text-align: center;
    font-size: xx-large;
}

.tableDescription {}

.tableHeaderRow {
    color: white;
}

.inverseInResponsive {
    display: flex;
    flex-direction: column;
}

.left {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
}

.left .totalCard{
    box-shadow: 0px 0px .4em grey;
    border-radius: .4em;
    padding: .4em;
}

.left>div {
    margin: .8rem;
    width: fit-content;
}


.responsiveBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.responsiveBox>div {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: .8rem;
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
    float: left;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

.tableHeaderRow>th {
    font-weight: bolder;
    color: white;
}

.sectionTitle {
    text-align: center;
    background-color: #003775;
    color: white;
    padding: 0.2rem 0;
    margin: 0.5rem 0;
    width: 100%;
}

.emBreve-img {
    width: 90%;
}

.formDateControlContainer {
    display:flex;
    flex-direction: row;
    justify-content: "space-evenly";
    width: "100%";
    margin-top: 1em;
}

.formDateControl {
    display: "flex";
    flex-direction: "row";
    justify-content: "space-evenly";
    width: "100%";
    gap: 1em;
}

table{
    box-shadow: 0 1px 5px 1px #efefef;
    width: 100%;
}
.toltipItemHeader{
    margin-bottom: .2em;
}
.toltip, .toltipItemHeader{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.toltipItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;    
    margin: .5em;
}

.accordion-header{
    box-shadow: 0 7px 10px -8px black;
}

.caixa{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 0;
    color: #003775;
    font-weight: bold;
    font-size: large;
}

.caixa-container{
    box-shadow: 0 0 8px 0px black;
    border-radius: 0.4em;
    padding: 0.6em;
    width: 24% !important;
    margin-right: .02em !important;
    margin-left: .5em !important;
    height: fit-content;
}

.caixa label{
    font-size: small;
    font-weight: 600;
    color: #326db1;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

}

.caixa .icon,
.toltip .icon{
    color: chocolate;
    margin-right: .4em;
}

.caixa .info-toltip-icon{
    background-color: #003775;
    color: white;
    padding: .4em;
    margin-right: .4em;
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
}

.caixa-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
}

.values{
    width: 100%;
    padding: 0 0 .6em 0;
    margin: 0 0 .4em 0;    
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid gray;
}

.values li{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    min-width: 50%;
    padding: .2em 0;
    margin: 0;
    font-size: small;
}
@media screen and (max-width: 1200px) {
    .caixa-container{
        width: 32% !important;
    }
}

@media screen and (max-width: 1000px) {
    .caixa-container{
        width: 48% !important;
    }
}

@media screen and (max-width: 800px) {
    .inverseInResponsive {
        flex-direction: column-reverse;
    }

    .responsiveBox {
        flex-direction: column;
        align-items: center;
    }

    .left {
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .left>div {
        width: 50%;
    }

    .caixa-container{
        width: 100% !important;
    }

    .formDateControlContainer {
        flex-direction: column;
    }

    table{
        overflow-x: hidden;
    }

}

@media screen and (max-width: 600px) {
    
}