.footer-tree-btn{
    display:none;
    width: 100%;
    margin: .5rem auto;
    padding: .5rem;
}

.footer-tree-btn button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 8px;
    border: 1px solid transparent;
    
    margin: .5rem;
}

.footer-tree-item-btn{
    display:none;
    width: 100%;
    margin: .5rem auto;
    padding: .5rem;
}

.footer-tree-item-btn button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 8px;
    border: 1px solid transparent;
    
    margin: .5rem;
}

.footer-tree-item-btn button:hover{
    background-color: #f5f5f5;
}

@media (max-width: 900px) {
    .footer-tree-btn{
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: .5rem auto;
        padding: .5rem;
    }
    .footer-tree-btn button{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;
        border-radius: 8px;
        border: 1px solid transparent;
        width: 100%;    
        margin: .5rem;
    }
    .footer-tree-btn button:hover{
        background-color: #f5f5f5;
    }
    .footer-tree-item-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: .5rem auto;
        padding: .5rem;
    }
    .footer-tree-item-btn button{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;
        border-radius: 8px;
        border: 1px solid transparent;
        width: 100%;    
        
        margin: .5rem;
    }
    .footer-tree-item-btn button:hover{
        background-color: #f5f5f5;
    }
}
    
