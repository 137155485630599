* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #000;
}

.header-container {
	width: 100%;
	height: 3em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: .8rem;
	background-color: #003775;
	color: #fff;
	margin-bottom: .3rem;
	position: fixed;
	z-index: 5;
	box-shadow: 1px 3px 5px 1px rgb(56 56 56);
}

.header-container .header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;

	width: 100%;
}

.header-container .header-content .header-content-left {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
}

.header-container .header-content .header-content-user {
	display: flex;
	justify-content: baseline;
	align-items: baseline;
	flex-direction: column;
	gap: 0;
	font-size: 1.2rem;
	z-index: 1;
}

#user-icon,
#bell-icon {
	width: 1.5rem;
	height: 1.5rem;
}

.icon-header {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	transition: all .2s ease-in;
	color: white;	
	background-color: #003775;
}

.icon-header:hover {
	color: white;	
	background-color: #ac8411;
}

.little-text {
	font-size: .8rem;
	color: #757575;
	font-weight: normal;
}

.header-container .header-content .header-content-user:hover {
	cursor: pointer;
}

.header-content-left-icons {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	gap: .5rem;
	font-size: 1.2rem;
	z-index: 1;
}





.header-content-info-notification {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #fff;
	border-radius: .5rem;
	box-shadow: 0 0 .5rem rgba(0, 0, 0, .3);
	padding: 0;
}

.header-content-info-notification h1 {
	font-size: 1.5rem;
	font-weight: bold;
	color: #003775;
	margin: 0 .5rem;
}

.header-content-info-notification>p {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	font-size: 1rem;
	color: #003775;
	width: 100%;
	padding: 0 .5rem;
	margin: 0;
}


.notification-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	background-color: #cdcdcd;
	color: #003775;
	text-decoration: none;
	cursor: pointer;
	transition: all .3s ease-in;
}

.notification-footer:hover {
	background-color: #003775;
	color: #fff;
}


.notification {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #fff;
	cursor: pointer;
	transition: all .3s ease-in;
	padding: .5rem;
	text-decoration: none;

}

.notification:hover {
	background-color: #e0e0e0;
}



.notification p {
	text-decoration: none;
	font-size: 1rem;
	color: #c82333;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	margin: 0 .5rem;


}


.notification span {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: end;
	width: 100%;

}



.header-content-info-user {
	display: none;
	background-color: #fff;
	color: #003775;
	border-radius: .75rem;
	border-top-right-radius: 0;
	position: absolute;
	top: 3em;
	right: 0;
	z-index: 1;
	box-shadow: 0.01em 0.01em 1em 0.01em grey;
	font-weight: 600;
}

.info-icon {
	border-bottom: 1px solid lightgray;
}

.header-content-info-user.open {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.header-content-info-user-icon {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	padding-top: .6rem;
}

.header-content-info-user.open span {
	width: 100%;
	font-size: 1.2rem;
	font-weight: bold;
	border-bottom: 1px solid #cdcdcd;
	transition: .5s;
	padding: .4rem;

	cursor: pointer;
}

.info-icon:hover{
	background-color: #f5f5f5;	
	border-radius: .75rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.header-content-info-user.open span:hover,
.header-content-info-user.open span .logout:hover {
	background-color: #f5f5f5;
}

.header-content-info-user.open span:focus {
	background-color: #cdcdcd;
}

.header-content-info-user.open span .logout {
	text-decoration: none;
	transition: .5s;
	color: #c82333;
}

.header-content-info-userspan .logout i {
	color: #c82333;
	width: 1.5rem;
}

.header-content-info-user.open .change-password {
	display: flex;
	align-items: center;
	color: #757575;
	text-decoration: none;
}

.header-content-info-user.open .change-password:hover {
	color: #ff0f3f;
	transition: .3s;
}
.header-content-info-user.open > span:hover,
.header-content-info-user-icon:hover,
.header-content-info-user.open .change-password:hover
{
	color: #ac8411;
	transition: .2s;
}
.logout{		
	border-radius: .75rem;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom: 0;
}
@media (max-width: 600px) {
	.header-container {

		height: fit-content;
		position: fixed;
		z-index: 5;
		align-items: flex-end;
		flex-direction: column;

	}

	.header-content-info-user {
		top: 3.1em;
	}
}