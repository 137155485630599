*{
    list-style: none;
    text-decoration: none;
}

.container-sidebar{
    display: flex;
    flex-direction: column;

    margin: 0;
    width: 80%;
}

.logo{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: .2rem;
}

.css-hyum1k-MuiToolbar-root{
    min-height: 0;
    padding: 0;
}