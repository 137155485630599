@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
*::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}
  

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 55, 117, 0.5);    /* color of the scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #003775;    /* color of the scroll thumb */
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
  html, body {
    margin: 0;
    height: 100%;
}

